import React, {useState} from 'react';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {observer} from "mobx-react-lite";
import PostService from "../../../services/PostService";

const Editoor = ({placeholder, editorHtml, setEditorHtml}) => {
    const [post, setPost] = useState([]);
    const [theme, setTheme] = useState('snow');

    const handleChange = (html) => {
        setEditorHtml(html);
    };


    const CleanArea = () => {
        setEditorHtml('')
    }

    const type = 'text'
    const date = '23.11.2023'
    const time = '15:38'


    Editoor.propTypes = {
        placeholder: PropTypes.string,
    };

    Editoor.modules = {
        toolbar: [
            [{header: '1'}, {header: '2'}, {font: []}],
            [{size: []}],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{list: 'ordered'}, {list: 'bullet'}, {indent: '-1'}, {indent: '+1'}],
            ['link', 'image', 'video'],
            ['clean'],
        ],
        clipboard: {
            // toggle to add extra line breaks when pasting HTML:
            matchVisual: false,
        },

    };

    Editoor.formats = [
        'header',
        'font',
        'size',
        'bold',
        'italic',
        'underline',
        'strike',
        'blockquote',
        'list',
        'bullet',
        'indent',
        'link',
        'image',
        'video',
    ];

    return (
            <div className='text-black article_create-box'>
                <ReactQuill
                    className='h-full'
                    theme={theme}
                    onChange={handleChange}
                    value={editorHtml}
                    modules={Editoor.modules}
                    formats={Editoor.formats}
                    bounds={'.app'}
                    placeholder={placeholder}
                />
            </div>
    );
};

export default observer(Editoor);