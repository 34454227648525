import React, { FC } from 'react';
import { inputStyles } from "../../CSS/StylesUI";

interface MyInputProps {
    placeholder?: string,
    type: string
    value: string,
    id?: string,
    icon?: string,
    name?: string,
    void: (e: any) => void,
}

const MyInput: FC<MyInputProps> = (props) => {
    return (
        <div className="col-md-12">
            <label htmlFor={props.id} className="form-label text-uppercase mt-2 mb-1 text-secondary">{props.placeholder}</label>
            <div className="input-group">
                <span className="input-group-text "><i className={`bx bx-${props.icon}`}></i></span>
                <input autoComplete="off"
                    className='form-control'
                    type={props.type}
                    value={props.value}
                    name={props.name}
                    id={props.id}
                    placeholder={props.placeholder}
                    onChange={props.void}
                    required />
            </div>
        </div>
    );
};

export default MyInput;