import React, { FC, useContext } from 'react';
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from 'yup';

import Editoor from "../Posts/Editor/Editoor";
import { Context } from "../../index";
import MyInput from "../UI/MyInput/MyInput";
import { RoutesList } from "../../routes";
import { useFetching } from '../../lib/useFetching';
import Loading from '../UI/Loading/Loading';


const CreateBanner: FC = () => {
    const { store } = useContext(Context)
    const { postStore } = useContext(Context)
    const navigate = useNavigate();


    const handleChoosePriority = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (/^\d*$/.test(e.target.value)) {
            formik.setFieldValue("priority", e.target.value);
        }
    };

    const handleEditHtml = (html: string) => {
        formik.setFieldValue("editorHtml", html);
    };

    const checkScriptAvailible = (value: string | undefined) => {
        let scriptReg = /<script/;
        return !scriptReg.test(value || '');
    };

    const CreateBanner = async () => {
        const thisDate = new Date();

        const date = thisDate.toLocaleDateString();
        const time = thisDate.toLocaleTimeString();

        const post = formik.values.editorHtml;
        const bannerLink = formik.values.bannerLink;
        const title = formik.values.title;
        const priority = formik.values.priority;

        const resultLink = () => {
            if (bannerLink[0] === '/') {
                return bannerLink;
            } else if (bannerLink.indexOf('https://') !== -1) {
                return bannerLink;
            } else {
                return 'https://' + bannerLink;
            }
        };

        const link = resultLink();

        const response = await postStore.addBanner(date, time, title.trim(), title.trim(), post, link.trim(), priority.trim())
        navigate(RoutesList.BANNERS_LIST_ROUTER)
        store.setSideBarActiveID(1)
    }

    const bannerValidationSchema = Yup.object({
        title: Yup.string()
            .test('scriptAvailible', 'Поле містить заборонені символи', checkScriptAvailible)
            .required(`Поле обов'язкове до заповнення `),
        bannerLink: Yup.string()
            .test('scriptAvailible', 'Поле містить заборонені символи', checkScriptAvailible)
            .required(`Поле обов'язкове до заповнення `),
        priority: Yup.string()
            .required(`Поле обов'язкове до заповнення `),
        editorHtml: Yup.string()
            .required(`Поле обов'язкове до заповнення `)
            .test('editorCheck', 'У полі не повинно бути нічого крім зображення', (value) => (value?.replace(/<[^>]*?>/gi, '').replace(/\s{1,}|\n{1,}/, '').trim().length || 0) === 0)
            .test('scriptAvailible', 'Поле містить заборонені символи', checkScriptAvailible)
            .test('editorImgAvailible', 'Поле може мати xоча б одне зображення', (value) => (value?.match(/<img/gi)?.length || 0) !== 0)
            .test('editorImgCount', 'Поле може мати тільки одне зображення', (value) => (value?.match(/<img/gi)?.length || 0) <= 1),
    })

    const formik = useFormik({
        initialValues: {
            title: '',
            bannerLink: '',
            priority: '',
            editorHtml: ''
        },
        validationSchema: bannerValidationSchema,
        onSubmit: (values) => {
            fetching()
        },
    });

    const { fetching, isError, isLoading } = useFetching(CreateBanner);

    return (
        <div className='p-4 bg-light rounded-md '>
            {isLoading && <Loading />}
            <div className='flex justify-between pb-4'>
                <div className='w-[500px] flex justify-between'>
                    <h3>Додавання банер</h3>
                    <div className='h-9'>
                        <MyInput
                            type='text'
                            id={'1'}
                            value={formik.values.title}
                            name='title'
                            placeholder={'Заголовок'}
                            icon={'text'}
                            void={formik.handleChange}
                        />
                    </div>
                    {formik.touched.title && formik.errors.title && <div className='input_error mt-1 px-2 py-1'>{formik.errors.title}</div>}
                    <div className='h-9'>
                        <MyInput
                            type='text'
                            id={'2'}
                            value={formik.values.bannerLink}
                            name='bannerLink'
                            placeholder={'Посилланя на офер'}
                            icon={'link-alt'}
                            void={formik.handleChange}
                        />
                    </div>
                    {formik.touched.bannerLink && formik.errors.bannerLink && <div className='input_error mt-1 px-2 py-1'>{formik.errors.bannerLink}</div>}
                    <div className='pt-3'>
                        <div className="input-group">
                            <MyInput
                                type={'text'}
                                id='3'
                                value={formik.values.priority}
                                name='priority'
                                placeholder={'Пріоритетність (Від 1, 1 - найбільший пріоритет)'}
                                void={handleChoosePriority}
                            />
                        </div>
                    </div>
                    {formik.touched.priority && formik.errors.priority && <div className='input_error mt-1 px-2 py-1'>{formik.errors.priority}</div>}
                </div>
            </div>
            <div className='h-80 bg-white text-black'>
                <Editoor
                    editorHtml={formik.values.editorHtml}
                    setEditorHtml={handleEditHtml}
                    placeholder='Write here...' />
            </div>
            {formik.touched.editorHtml && formik.errors.editorHtml && <div className='input_error mt-1 px-2 py-1'>{formik.errors.editorHtml}</div>}


            <div className='flex justify-between w-full pt-4'>
                <button
                    onClick={() => formik.handleSubmit()}
                    className='btn btn-secondary rounded-md'
                    disabled={isLoading}
                    type='submit'
                >
                    Додати банер
                </button>
            </div>
        </div>
    );
};

export default observer(CreateBanner);