import React, { FC, useContext, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { Context } from "../../index";
import MyInput from "../UI/MyInput/MyInput";
import Editoor from "./Editor/Editoor";
import { useFetching } from '../../lib/useFetching';
import Loading from '../UI/Loading/Loading';

interface CreateTaskModalProps {
    postId: string,
    post: string,
    title: string,
    isActive: boolean,
    category: string,
    description: string,
    priority: string,
    handlePopUpOpen: () => void,
}

const EditPostModal: FC<CreateTaskModalProps> = (props) => {
    const { postStore } = useContext(Context)

    const [isActive, setIsActive] = useState<boolean>(props.isActive)
    const [isShowCategory, setIsShowCategory] = useState<any>(false);
    const [categoryPost, setCategoryPost] = useState<any>(props.category);

    const handleShowCategories = () => {
        setIsShowCategory(!isShowCategory);
    };
    const handleChooseCategories = (e: any) => {
        setCategoryPost(e.target.innerText);
        setIsShowCategory(!isShowCategory);
        console.log(categoryPost, e.target.innerText)
    };
    const handleChoosePriority = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (/^\d*$/.test(e.target.value)) {
            formik.setFieldValue("priority", e.target.value);
        }
    };
    const handleEditHtml = (html: string) => {
        formik.setFieldValue("editorHtml", html);
    };
    const setArticleIsActive = () => {
        setIsActive(!isActive)
    };
    const Close = () => {
        props.handlePopUpOpen()
    };


    const EditPost = async () => {
        const thisDate = new Date()

        const id = props.postId;
        const date = thisDate.toLocaleDateString()
        const time = thisDate.toLocaleTimeString()

        const post = formik.values.editorHtml;
        const title = formik.values.title;
        const priority = formik.values.priority;
        const category = categoryPost;

        const response = await postStore.editPost(id, date, time, isActive, title, title, category, post, priority)
        props.handlePopUpOpen()
        postStore.getPosts(1, 20)
    }
    const checkScriptAvailible = (value: string | undefined) => {
        let scriptReg = /<script/;
        return !scriptReg.test(value || '');
    };
    const bannerValidationSchema = Yup.object({
        title: Yup.string()
            .test('scriptAvailible', 'Поле містить заборонені символи', checkScriptAvailible)
            .required(`Поле обов'язкове до заповнення `),
        priority: Yup.string()
            .required(`Поле обов'язкове до заповнення `),
        editorHtml: Yup.string()
            .required(`Поле обов'язкове до заповнення `)
            .test('editorCheck', 'Поле повинно мати якийсь текст', (value) => (value?.replace(/<[^>]*?>/gi, '').replace(/\s{1,}|\n{1,}/, '').trim().length || 0) !== 0)
            .test('scriptAvailible', 'Поле містить заборонені символи', checkScriptAvailible)
    });
    const formik = useFormik({
        initialValues: {
            title: props.title,
            priority: props.priority,
            editorHtml: props.post
        },
        validationSchema: bannerValidationSchema,
        onSubmit: (values) => {
            fetching()
        },
    });

    const { fetching, isError, isLoading } = useFetching(EditPost);

    return (
        <div className="modal show edit_post" role="dialog">
            {isLoading && <Loading />}
            <div className="modal-dialog" role="document">
                <div className="modal-content p-4">
                    <div className='d-flex flex-column align-items-start'>
                        <h3>Редагування статті</h3>
                        <div className='h-9 w-100'>
                            <MyInput
                                type='text'
                                id={'1'}
                                value={formik.values.title}
                                name='title'
                                placeholder={'Заголовок'}
                                icon={'text'}
                                void={formik.handleChange}
                            />
                        </div>
                        {formik.touched.title && formik.errors.title && <div className='input_error mt-1 px-2 py-1'>{formik.errors.title}</div>}
                        <div className='pt-3'>
                            <p className='text-uppercase mb-1 text-secondary'>Категорія</p>
                            <div className="input-group">
                                <button className="btn btn-outline-secondary dropdown-toggle w-100"
                                    onClick={handleShowCategories}
                                >{categoryPost}
                                </button>
                                <ul className={"dropdown-menu w-50 " + (isShowCategory ? 'show' : '')}>
                                    <li className="dropdown-item" onClick={(e) => handleChooseCategories(e)}>FINANCE</li>
                                    <li className="dropdown-item" onClick={(e) => handleChooseCategories(e)}>HEALTH</li>
                                    <li className="dropdown-item" onClick={(e) => handleChooseCategories(e)}>ANOTHER</li>
                                </ul>
                            </div>
                        </div>
                        <div className='pt-3'>
                            <div className="input-group">
                                <MyInput
                                    type={'text'}
                                    id={'2'}
                                    value={formik.values.priority}
                                    name='priority'
                                    placeholder={'Пріоритетність'}
                                    void={handleChoosePriority}
                                />
                            </div>
                        </div>
                        {formik.touched.priority && formik.errors.priority && <div className='input_error mt-1 px-2 py-1'>{formik.errors.priority}</div>}
                    </div>
                    <div className='h-9 pt-4'>
                        <div className="form-check">
                            <input className={"form-check-input " + (isActive ? 'bg-black border-black' : '')}
                                onChange={setArticleIsActive}
                                checked={isActive}
                                type="checkbox"
                                value=""
                                id="flexCheckDefault2"
                            />
                            <label className="form-check-label" htmlFor="flexCheckDefault2">Показати</label>
                        </div>
                    </div>
                    <div className='h-80 bg-white text-black'>
                        <Editoor
                            editorHtml={formik.values.editorHtml}
                            setEditorHtml={handleEditHtml}
                            placeholder='Write here...' />
                    </div>
                    {formik.touched.editorHtml && formik.errors.editorHtml && <div className='input_error mt-1 px-2 py-1'>{formik.errors.editorHtml}</div>}
                    <div className='flex justify-between w-75 pt-4'>
                        <button
                            onClick={Close}
                            className='btn btn-light rounded-md w-25'
                        >
                            Назад
                        </button>
                        <button
                            onClick={() => formik.handleSubmit()}
                            className='btn btn-secondary rounded-md mx-2 w-25'
                            disabled={isLoading}
                            type='submit'
                        >
                            Зберегти зміни
                        </button>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default EditPostModal;